import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import SnuPage from './Pages/SnuPage';
import TiutPage from './Pages/TiutPage';
import CollegePage from './Pages/collegePage';
import School from './Pages/School';
import Hospital from './Pages/Hospital';
import Hotel from './Pages/Hotel';
import Purchase from './Pages/Purchase';
import Teaching from './Pages/Teaching';
import TrainingPlacement from './Pages/TrainingPlacement';
import MisReport from './Pages/MisReport';
import AuditFeedback from './Pages/AuditFeedback';
import FinanceReview from './Pages/FinanceReview';
import Media from './Pages/Media';
import CollegeV1 from './Pages/collegeV1';
import CollegeV2 from './Pages/CollegeV2';
import HotelDarjeeling from './Pages/HotelDarjeeling';

const root = ReactDOM.createRoot(document.getElementById('root')); // Create root
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/snu" element={<SnuPage />} />
        <Route path="/tiut" element={< TiutPage />} />
        <Route path="/collegeManagement" element={< CollegePage />} />
        <Route path='/collegeV1' element={<CollegeV1 />} />
        <Route path='/collegev2' element={<CollegeV2/>} />
        <Route path="/schoolManagement" element={< School />} />
        <Route path="/hospitalManagement" element={< Hospital />} />
        <Route path="/media" element={<Media/>}/>
        <Route path="/hotelManagement" element={< Hotel />} />
        <Route path='/hotelDarjeeling' element={<HotelDarjeeling/>} />
        <Route path="/purchaseManagement" element={< Purchase />} />
        <Route path="/teachingManagement" element={< Teaching />} />
        <Route path="/training&placement" element={< TrainingPlacement />} />
        <Route path="/audit&feedback" element={< AuditFeedback />} />
        <Route path="/finance&review" element={< FinanceReview />} />
        <Route path="/mis" element={< MisReport />} />
      </Routes>
    </Router>
  </React.StrictMode>
);
