import React from 'react';
import logo1 from '../assets/TIG Logo1.png';
import logo2 from '../assets/techtron-logo-fotor-20240903162951.png';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import clgImage from '../assets/clgBlack.png';


const CollegeV2 = () => {
  return (
    <div className="clg-App">
      <div className="logo-container">
        <img src={logo1} alt="Logo 1" style={styles.topLogo1} />
        <img src={logo2} alt="Logo 2" style={styles.topLogo2} />
      </div>
      <section className="clg-modules">
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    onClick={() => window.location.href ='http://www.techtron.net:9591/'}
                >
                    <Link className="projects-link" >
                        <div className="icon-section-clg">
                            <img src={clgImage} alt="Sales Module" className="project-image" />
                        </div>
                        <div className="project-title">
                        College Admission
                        </div>
                    </Link>
                </motion.div>

                <motion.div
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    onClick={() => window.location.href ='http://www.techtron.net:9591/studentdetails/index'}
                >
                    <Link className="projects-link" >
                        <div className="icon-section-clg">
                            <img src={clgImage} alt="Sales Module" className="project-image" />
                        </div>
                        <div className="project-title">
                            Student Details
                        </div>
                    </Link>
                </motion.div>
                <motion.div
                    whileHover={{ scale: 1.05 }}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    onClick={() => window.location.href ='http://www.techtron.net:9591/enquiry/enquiry'}
                >
                    <Link className="projects-link" >
                        <div className="icon-section-clg">
                            <img src={clgImage} alt="Sales Module" className="project-image" />
                        </div>
                        <div className="project-title">
                        Student Enquery
                        </div>
                    </Link>
                </motion.div>

            </section>
    </div>
  )
}
const styles = {

  topLogo1: {
    width: '110px',
    height: '100px',
    animation: 'fadeInScale 2s ease-in-out',
  },
  topLogo2: {
    width: 'auto',
    height: '120px',
    animation: 'fadeInScale 2s ease-in-out',
  },
}

export default CollegeV2